<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <!-- <vuexy-logo />
        <h2 class="brand-text text-primary ml-1">
          Vuexy
        </h2> -->

        <!-- <img
          height="30px"
          :src="require('@/assets/images/logo/logo.png')"
          alt="Revivify Logo"
        /> -->
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <!-- <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img fluid :src="imgUrl" alt="Forgot password V2" />
        </div>
      </b-col> -->
      <b-col lg="8" class="d-lg-flex align-items-center p-5 forgot-page">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img fluid :src="imgUrl" alt="Login V2" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Forgot password-->
      <b-col
        v-if="statusForgotRest == 'reset'"
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title title-tag="h2" class="font-weight-bold mb-1">
            Forgot Password?
          </b-card-title>
          <b-card-text class="mb-2">
            Enter your email and we'll send you instructions to reset your
            password
          </b-card-text>

          <validation-observer ref="simpleRules" v-if="showInputs">
            <b-form
              class="auth-forgot-password-form mt-2"
              @submit.prevent="validationFormReset"
            >
              <b-form-group label="Email" label-for="forgot-password-email">
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="forgot-password-email"
                    v-model="resetUserEmail"
                    :state="errors.length > 0 ? false : null"
                    name="forgot-password-email"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-button type="submit" variant="primary" block>
                Send reset link
              </b-button>
            </b-form>
          </validation-observer>

          <b-alert show variant="success" v-if="!showInputs">
            <div class="alert-body">
              <feather-icon class="mr-25" icon="StarIcon" />
              <span class="ml-25 mt-2">
                {{ this.resMessage }}
              </span>
            </div>
          </b-alert>

          <p class="text-center mt-2">
            <b-link :to="{ name: 'auth-login-v2' }">
              <feather-icon icon="ChevronLeftIcon" /> Back to login
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Forgot password-->

      <!-- Reset password-->
      <b-col
        v-if="statusForgotRest == 'forgot'"
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title title-tag="h2" class="font-weight-bold mb-1">
            Reset Password
          </b-card-title>
          <b-card-text class="mb-2">
            Your new password must be different from previously used passwords
          </b-card-text>

          <!-- form -->
          <validation-observer ref="simpleRules">
            <b-form
              class="auth-reset-password-form mt-2"
              method="POST"
              @submit.prevent="validationFormForgot"
            >
              <!-- password -->
              <b-form-group label="New Password" label-for="reset-password-new">
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="Password"
                  rules="required|password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="reset-password-new"
                      v-model="forgotPassword"
                      :type="password1FieldType"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      name="reset-password-new"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="password1ToggleIcon"
                        @click="togglePassword1Visibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- confirm password -->
              <b-form-group
                label-for="reset-password-confirm"
                label="Confirm Password"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Confirm Password"
                  rules="required|confirmed:Password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="reset-password-confirm"
                      v-model="forgotPasswordcPassword"
                      :type="password2FieldType"
                      class="form-control-merge"
                      :state="errors.length > 0 ? false : null"
                      name="reset-password-confirm"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="password2ToggleIcon"
                        @click="togglePassword2Visibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit button -->
              <b-button block type="submit" variant="primary">
                Set New Password
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <b-link :to="{ name: 'auth-login-v2' }">
              <feather-icon icon="ChevronLeftIcon" /> Back to login
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Reset password-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BRow,
  BCol,
  BLink,
  BCardTitle,
  BCardText,
  BImg,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BInputGroup,
  BInputGroupAppend,
  BAlert,
} from "bootstrap-vue";
import { required, email } from "@validations";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AuthServices from "@/apiServices/AuthServices";

export default {
  components: {
    VuexyLogo,
    BRow,
    BCol,
    BLink,
    BImg,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    ValidationProvider,
    ValidationObserver,
    BAlert,

    BInputGroup,
    BInputGroupAppend,
  },
  data() {
    return {
      showInputs: true,
      resMessage: "",

      statusForgotRest: "reset",

      resetUserEmail: "",
      //sideImg: require("@/assets/images/pages/forgot-password-v2.svg"),
      sideImg: require("@/assets/images/pages/revivify-labels.svg"),
      required,
      email,

      forgotPasswordUserEmail: "",
      forgotPasswordcPassword: "",
      forgotPassword: "",
      password1FieldType: "password",
      password2FieldType: "password",
      newPassword: "",
    };
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/forgot-password-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },

    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    password1ToggleIcon() {
      return this.password1FieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    password2ToggleIcon() {
      return this.password2FieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  beforeMount() {
    this.loadDefault();
  },
  methods: {
    loadDefault() {
      const { token } = this.$route.query;
      if (token === undefined) {
        console.log("TOKEN_NOT_FOUND");
      } else {
        console.log("TOKEN_FOUND: ", token);
        this.verifyToken(token);
      }
    },

    validationFormReset() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          const sendData = { email: this.resetUserEmail };

          AuthServices.RestLink(sendData)
            .then((response) => {
              //console.log("RESRT_LINK: " + JSON.stringify(response.data));

              if (response.data.status) {
                this.toastNotificatoin(
                  response.data.message,
                  "UserIcon",
                  "success"
                );
                //Hide the inputs and wait for user actions
                this.showInputs = false;
                this.resMessage = response.data.message;
              } else {
                this.toastNotificatoin(
                  response.data.message,
                  "UserIcon",
                  "danger"
                );
              }
            })
            .catch((error) => {
              console.error("There was an error!", error);
            });
        }
      });
    },

    togglePassword1Visibility() {
      this.password1FieldType =
        this.password1FieldType === "password" ? "text" : "password";
    },
    togglePassword2Visibility() {
      this.password2FieldType =
        this.password2FieldType === "password" ? "text" : "password";
    },
    validationFormForgot() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          if (this.forgotPasswordcPassword == this.forgotPassword) {
            this.newPassword = this.forgotPasswordcPassword;
          } else {
            this.toastNotificatoin(
              "Password Not Matched",
              "UserIcon",
              "warning"
            );
            return;
          }

          const { token } = this.$route.query;
          const sendData = { user_token: token, password: this.newPassword };

          AuthServices.updateForgotPassword(sendData)
            .then((response) => {
              //console.log("UPDATE_PASS: " + JSON.stringify(response.data));

              if (response.data.status) {
                this.toastNotificatoin(
                  response.data.message,
                  "UserIcon",
                  "success"
                );
                this.$router.push("/onboarding").catch();
              } else {
                this.toastNotificatoin(
                  response.data.message,
                  "UserIcon",
                  "danger"
                );
              }
            })
            .catch((error) => {
              console.error("There was an error!", error);
            });
        }
      });
    },

    verifyToken(tokenVal) {
      const sendData = { token: tokenVal };

      AuthServices.VerifyToken(sendData)
        .then((response) => {
          if (response.data.status) {
            this.statusForgotRest = "forgot";
          } else {
            this.statusForgotRest = "reset";
          }

          console.log("VERIFICATOIN: ", this.statusForgotRest);
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    },

    toastNotificatoin(message, icon, varient) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: message,
          icon: icon,
          variant: varient,
        },
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
@media (max-width: 768px) {
  .forgot-page .px-5 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
</style>
